 import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(
    public updates:SwUpdate
  ) {
    if(updates.isEnabled) {
      timer(0,60000).subscribe( () => {
        updates.checkForUpdate().then(() => {
          console.warn("[ - Update Check - ]");
        });
      })
    }
  }

  /** consulta por actualizaciones de la pwa */
  public checkForUpdates(): void{ 
    this.updates.available.subscribe( e => {
      this.promptUser();
    });
  }

  /** inicia la actualización por reload  */
  public promptUser():void{
    console.warn("[ - Update encontrada - Actualizando - ]");
    this.updates.activateUpdate().then(() => {
      document.location.reload();
    });
  }


}
