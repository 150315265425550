/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./app.component";
import * as i7 from "ngx-device-detector";
import * as i8 from "./conexion-api.service";
import * as i9 from "./services/update.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ion-app", [], null, null, null, i2.View_IonApp_0, i2.RenderType_IonApp)), i1.ɵdid(1, 49152, null, 0, i3.IonApp, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "button", [["class", "floatcall"], ["id", "floatcall"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.AyudaModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "assets/imgs/call.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, 0, 1, "ion-router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i3.IonRouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], [8, null], i3.Config, i3.NavController, i5.Location, i1.ElementRef, i4.Router, i1.NgZone, i4.ActivatedRoute, [3, i3.IonRouterOutlet]], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 5, "div", [["id", "overlay-portrait"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "ion-icon", [["name", "phone-portrait"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(9, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Su celular debe permanecer en posici\u00F3n vertical"]))], function (_ck, _v) { _ck(_v, 5, 0); var currVal_0 = "phone-portrait"; _ck(_v, 9, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i6.AppComponent, [i3.Platform, i7.DeviceDetectorService, i3.NavController, i8.ConexionApiService, i9.UpdateService, i4.Router, i5.LocationStrategy, i3.ModalController], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
