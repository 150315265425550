 <ion-app>



  <button id="floatcall" class="floatcall" (click)="AyudaModal()" >
    <img src="assets/imgs/call.svg">
  </button>

  <ion-router-outlet></ion-router-outlet>

  <div id="overlay-portrait">
    <div class="content">
      <ion-icon name="phone-portrait"></ion-icon>
      <h2>Su celular debe permanecer en posición vertical</h2>
    </div>
  </div>
</ion-app>